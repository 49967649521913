import { useState, useEffect } from 'react';
import "./chat.css";

import { padding, paddingY, paddingX } from '@coachhubio/nova-spaces';
import { Column, Row, WideButton, Content } from '../components/Common';
import { CircularProgress, TextField } from '@coachhubio/ui-components';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import config from '../utils/config';
import {
    CheckCircleIcon,
    WarningCircleFullIcon,
    XmarkIcon
} from '@coachhubio/nova-icon';

import {
    StarIcon
} from '@coachhubio/nova-icon';

const ImageRow = styled(Row)`
  flex-grow: 0;
  ${padding('l')}
`;

const StarIconSized = styled(StarIcon)`
    min-width: 50px;
    min-height: 50px;
    margin: 20px;
    color: rgba(181, 182, 201, 1);

    @media only screen and (max-width: 920px) {
        min-width: 50px;
        min-height: 50px;
        margin: 5px;
    }
`;

const Card = styled(Column)`
    background-color: white;
    max-height: 500px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;

    @media only screen and (min-width: 920px) {
        ${paddingX('5xl')};
        ${paddingY('l')}
    }

    @media only screen and (max-width: 920px) {
        min-width: 90%;
        font-size: 12px;
        ${paddingX('none')};

        textarea {
            width: 100% !important;
            font-size: 16px;
            margin: 0;
        }
    }
`;

const ResponsiveRow = styled(Row)`
    @media only screen and (min-width: 920px) {
        min-width: 500px;
    }
    
    @media only screen and (max-width: 920px) {
        flex: 1;
    }
`;

const TextColumn = styled(Column)`
    font-size: 25px;

    @media only screen and (max-width: 920px) {
        font-size: 20px;
    }
`;

const LabelColumn = styled(Column)`
`;

const TextFieldWide = styled(TextField)`
    @media only screen and (max-width: 920px) {
        width: 100%;
        div {
            width: 275px;
        }
    }
`;

export const FeedbackScreen = () => {

    const [stars, setStars] = useState(5);
    const [rating, setRating] = useState<number | null>(null);
    const [hover, setHover] = useState(0);
    const [sessionId, setSessionId] = useState("");
    const [token, setToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { register, reset, setValue, handleSubmit } = useForm();
    const { user } = useAuth();
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const response = await sendFeedback(token!, sessionId, data)

        setIsLoading(false);

        if (response.status == 200) {
            setSent(true)
        } else {
            navigate(0)
        }
    }

    useEffect(() => {
        setValue("rating", rating)
        if (rating) {
            setError(null)
        } else {
            setError("Please rate the app")
        }
    }, [rating])

    useEffect(() => {
        if (user) {
            setSessionId(user.sessionId);
            setToken(user.authToken!);
            // window.addEventListener("beforeunload", (ev) => {
            //     if (!sent && user.sessionId && user.authToken) {
            //         sendFeedback(user.authToken!, user.sessionId, { feedback: "", rating: 0 })
            //         ev.preventDefault();
            //         return ev.returnValue = 'Are you sure you want to close?';
            //     }
            //     return;
            // });
        }
    }, [user])

    const sendFeedback = async (token: string, sessionId: string, data: any) => {
        if (token && sessionId) {
            return await fetch(config.endpoint_feedback,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token!
                    },
                    body: JSON.stringify({
                        feedback: data.feedback,
                        rating: data.rating,
                        session_id: sessionId
                    })
                })
        } else {
            return { status: 500 }
        }
    }

    useEffect(() => {
        register("feedback", { required: false });
        register("rating", { required: true });
        document.title = `${config.page_title} - Feedback`;
    }, []);

    return (
        <Content>
            <ImageRow>
                <img src="images/coachhub_logo_white.png" alt="coachhub logo" width="180" />
            </ImageRow>
            <Card>
                <ResponsiveRow style={{ maxHeight: '100px' }}>
                    <TextColumn>
                        Thank you!<br /> How would you rate your session?
                    </TextColumn>
                </ResponsiveRow>
                <ResponsiveRow style={{ maxHeight: '100px' }}>
                    {
                        [...Array(stars).keys()].map(s => {
                            const star = s + 1
                            return (
                                <div key={star}>
                                    <Column className={`
                                    ${(star <= (hover > (rating || 0) ? hover : (rating || 0))) ? 'star-hover' : ''}
                                `}>
                                        <StarIconSized
                                            onMouseEnter={() => !sent ? setHover(star) : ()=>{}}
                                            onMouseLeave={() => !sent ? setHover(0) : ()=>{}}
                                            onClick={() => !sent ? setRating(star) : ()=>{} }
                                        />
                                    </Column>
                                    <LabelColumn>
                                        {
                                            (star == 1 ?
                                                <span>Very bad</span>
                                                :
                                                (
                                                    star == 5 ?
                                                        <span>Very Good</span>
                                                        :
                                                        <></>
                                                )
                                            )
                                        }
                                    </LabelColumn>
                                </div>
                            )
                        })
                    }
                </ResponsiveRow>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true} data-testid="user-form">
                    <ResponsiveRow style={{ maxHeight: '200px', marginTop: '30px' }}>
                        <TextFieldWide
                            name="feedback"
                            onChange={e => setValue("feedback", e.target.value)}
                            fullWidth={true}
                            placeholder="What went well? What can be improved?"
                            minRows={5}
                            multiline={true}
                            disabled={sent}
                        />
                        <TextFieldWide
                            name="rating"
                            type="number"
                            fullWidth={true}
                            placeholder="Rating"
                            style={{ display: 'none' }}
                            value={rating}
                            onInput={e => setRating(parseInt((e.target as HTMLInputElement).value))}
                        />
                    </ResponsiveRow>
                    <ResponsiveRow style={{ marginTop: '20px', maxHeight: '200px' }}>
                        {
                            !sent ?
                                <WideButton type="submit" disabled={isLoading || sent || !rating} data-testid="user-button">
                                    {isLoading ? <CircularProgress size={30} /> : 'Send feedback'}
                                </WideButton>
                                : <></>
                        }
                    </ResponsiveRow>
                    {
                        sent ?
                            <ResponsiveRow style={{
                                marginTop: '20px',
                                maxHeight: '200px',
                                textAlign: 'left',
                                justifyContent: 'start',
                                backgroundColor: '#EDF8F6',
                                padding: '10px',
                                borderRadius: '5px',
                            }}>
                                <CheckCircleIcon />
                                <div style={{ flexShrink: '0', marginLeft: '15px', marginTop: '2px' }}>
                                    Thank you for your feedback!
                                </div>
                                {/* <div style={{ display: 'flex', justifyContent: 'end', flexGrow: '2' }}>
                                    <XmarkIcon onClick={() => { setSent(false) }} />
                                </div> */}
                            </ResponsiveRow>
                            :
                            <></>
                    }
                </form>
            </Card>
        </Content>
    )
};
