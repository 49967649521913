import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import './App.css';
import { MainScreen } from './screens/Main';
import { SignInScreen } from './screens/SignIn';
import { FeedbackScreen } from './screens/Feedback';
import { WaitListScreen } from './screens/Waitlist';
import { AuthProvider } from './providers/AuthProvider';
import { RequireAuth } from './context/AuthContext';
import GAListener from './providers/withTracker';

function App() {
  return (
    <BrowserRouter>
      <GAListener>
        <AuthProvider>
          <Routes>
            <Route path="/">
              <Route index element={
                <RequireAuth>
                  <MainScreen />
                </RequireAuth>
              } />
              <Route path="login" element={<SignInScreen />} />
              <Route path="feedback" element={
                <RequireAuth>
                  <FeedbackScreen />
                </RequireAuth>
              } />
              <Route path="waitlist" element={
                <RequireAuth>
                  <WaitListScreen />
                </RequireAuth>
              } />
            </Route>
          </Routes>
        </AuthProvider>
      </GAListener>
    </BrowserRouter>
  );
}

export default App;
