export type MessageType = {
    fromMe: boolean,
    text: string,
    timestamp: string
  }
  
  export enum QuestionTypeEnum {
    Voice = 1,
    Text = 2
  }
  