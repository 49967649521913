// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

import config from './config';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: config.firebase_api_key,
  authDomain: "chamy-52797.firebaseapp.com",
  projectId: "chamy-52797",
  storageBucket: "chamy-52797.appspot.com",
  messagingSenderId: "421258321918",
  appId: "1:421258321918:web:0d6e03e8db28648342aaaf",
  measurementId: "G-QYHES5DJJB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();