import styled from 'styled-components';
import { padding, paddingBottom, paddingY } from '@coachhubio/nova-spaces';
import { Button } from '@coachhubio/ui-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1; overflow: auto;
  overflow-y: hidden;
  min-height: 100vh;
  width: 100%;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
`;

export const Content = styled(Wrapper)`
    align-items: center;
    justify-content: center;
    background-color: #1E2D79;
    color: #303f9f;
    font-family: 'Montserrat';
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ColumnButton = styled(Column)`
    flex: 1;
    ${paddingY('s')}
`
export const ColumnTextField = styled(Column)`
  flex: 1;
  ${paddingBottom('xs')}
  & input {
    height: 25px;
  }
`;

export const WideButton = styled(Button)`
width: 100% !important;
`;

export const ButtonIcon = styled(Button)`
  background: none !important;
  color: #1E2D79 !important;
  border: none !important;
  width: fit-content !important;

  svg {
    margin-top: 8px;
  }
`;