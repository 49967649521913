
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getColor } from '@coachhubio/nova-colors';
import { padding, paddingBottom, paddingX } from '@coachhubio/nova-spaces';
import { Column, Row, Wrapper } from '../components/Common';
import { Button, CircularProgress, TextField } from '@coachhubio/ui-components';
import { Select, Option, SelectValue } from '@coachhubio/nova-select';
import * as CompanyEmailValidator from 'company-email-validator';

import styled from 'styled-components';
import config from '../utils/config';

const InputRow = styled(Row)`
width: 100vw;
@media only screen and (min-width: 768px) {
  width: 50vw;
}
  max-height: 50px;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  ${paddingBottom('m')}
`;

const ColumnButton = styled(Column)`
    align-items: center;
    justify-content: center;
    flex: 4;
    @media only screen and (min-width: 768px) {
        flex: 1;
    }
  ${paddingX('l')}
`
const ColumnTextField = styled(Column)`
  flex: 1;
  ${paddingX('l')}
`

const WideButton = styled(Button)`
    width: 100% !important;
`;

const TextRow = styled(Row)`
  flex-grow: 0;
  align-items: center;
    justify-content: center;
  ${padding('s')}
`;

const StyledSelect = styled(Select) <{ value?: string }>`
  & ${SelectValue} {
    ${({ value }) => (!value || value.length === 0 ? getColor('neutral60') : '')}
  }
`;

interface RequestCodeProps {
    onSubmit: any,
    error: boolean,
    isLoading: boolean,
    setEmail: any,
    email: string,
    setLanguage: any,
    language: string
}

export const RequestCode = ({ onSubmit, error, isLoading, setEmail, email, setLanguage, language }: RequestCodeProps) => {
    const { register, reset, setValue, handleSubmit } = useForm();
    const [errorValidation, setErrorValidation] = useState(false);

    useEffect(() => {
        register("email", { required: true });
    }, []);

    useEffect(() => {
        //setErrorValidation(!CompanyEmailValidator.isCompanyEmail(email));
    }, [email])

    return (
        <div>
            <TextRow>
                {
                    errorValidation && email ?
                        <p className="error-alert">
                            Please provide a Business Email.
                        </p>
                        :
                        <></>
                }
            </TextRow>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true} data-testid="user-form">
                <InputRow>
                    <ColumnTextField>
                        <TextField
                            name="email"
                            onChange={e => setValue("email", e.target.value)}
                            label={'Business Email'}
                            type="text"
                            autoFocus={true}
                            disabled={isLoading}
                            value={email}
                            onInput={e => setEmail((e.target as HTMLInputElement).value)}
                            error={errorValidation}
                        />
                    </ColumnTextField>
                </InputRow>
                <InputRow>
                    <ColumnTextField>
                        <StyledSelect
                            value={language}
                            onChange={setLanguage}
                            placeholder="Select Language"
                            itemsSelectedMessage={config.languages.find(r => r.value === language)?.label}
                        >
                            {config.languages.map((option, index) => (
                                <Option
                                    value={option.value}
                                    title={option.label}
                                    key={`${option.label}-${index}`}
                                />
                            ))}
                        </StyledSelect>
                    </ColumnTextField>
                </InputRow>
                <InputRow>
                    <ColumnButton>
                        <WideButton type="submit" disabled={isLoading || errorValidation} data-testid="user-button">
                            {isLoading ? <CircularProgress size={30} /> : 'Start Conversation'}
                        </WideButton>
                    </ColumnButton>
                </InputRow>
            </form>
        </div>
    )
}