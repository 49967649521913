export const ctx = new AudioContext();
export let scriptNode: ScriptProcessorNode;
export let analyser: AnalyserNode;
export let mediaStream: MediaStream;


let sourceNode: MediaStreamAudioSourceNode;
let compressor: DynamicsCompressorNode;
let filter: BiquadFilterNode;

compressor = ctx.createDynamicsCompressor();
compressor.threshold.value = -50;
compressor.knee.value = 40;
compressor.ratio.value = 12;
//compressor.reduction.value = -20;
compressor.attack.value = 0;
compressor.release.value = 0.25;

filter = ctx.createBiquadFilter();
filter.Q.value = 8.30;
filter.frequency.value = 355;
filter.gain.value = 3.0;
filter.type = 'bandpass';
filter.connect(compressor);

navigator.mediaDevices.getUserMedia({ audio: true }).then((rawStream) => {
    mediaStream = rawStream;
    analyser = ctx.createAnalyser();
    sourceNode = ctx.createMediaStreamSource(rawStream);

    scriptNode = ctx.createScriptProcessor(4096, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    sourceNode.connect(filter);
    sourceNode.connect(analyser);
    analyser.connect(scriptNode);

    sourceNode.connect(scriptNode);
    scriptNode.connect(ctx.destination);
})