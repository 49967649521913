import { useEffect, useState } from 'react'

import { makeStyles, createStyles } from '@coachhubio/ui-components';
import { AudioPlayer } from './AudioPlayer';
import { AudioWave } from './AudioWave';
import styled from 'styled-components';

const Content = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #242424;
    justify-content: center;
    align-items: center;
    color: white;
`;

const ImageCropper = styled.div`
    width: 250px;
    height: 250px;
    position: relative;
    overflow: hidden;
    transition: outline 100ms;
    -webkit-border-radius: 125px;
    -moz-border-radius: 125px;
    -khtml-border-radius: 125px;
    border-radius: 125px;

    @media only screen and (max-width: 920px) {
        width: 200px;
        height: 200px;
    }
`;

const AudioWaveContainer = styled.div`
    float: left;
    left: 192px;
    bottom: 50px;
    position: relative;

    @media only screen and (max-width: 920px) {
        left: 150px;
        bottom: 50px;
    }
`;

const Picture = styled.img`
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
    position: absolute;
`;

interface BotScreenProps {
    speak: boolean,
    playAudio: any,
    playing: boolean,
    thinking: boolean,
    setThinking: any,
    loaded: boolean,
    src: string,
    onEnded: any
}

export const OffScreen = ({ speak, playAudio, playing, thinking, setThinking, loaded, src, onEnded }: BotScreenProps) => {
    const [voiceEffect, setVoiceSffect] = useState("");
    const [average, setAvg] = useState(0);

    useEffect(() => {
        if (speak === false)
            return;

        setVoiceSffect('');
        playAudio({})

    }, [speak])

    useEffect(() => {
        if (playing === false)
            return;

        setThinking(false);

    }, [playing, voiceEffect])


    return (
        <Content>
            {
                true ?
                    <div>
                        <ImageCropper
                            style={{ boxShadow: `0 0 0 ${average}px rgba(141, 143, 165, 0.2)` }}
                        >
                            <Picture src='images/picture.png'></Picture>
                        </ImageCropper>
                        <AudioPlayer src={src} playing={playing} setAvg={setAvg} onEnded={onEnded} />
                        <AudioWaveContainer>
                            <AudioWave thinking={thinking} muted={false} average={average}></AudioWave>
                        </AudioWaveContainer>
                    </div>
                    :
                    "Loading..."
            }
        </Content>
    );
}