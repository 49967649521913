import { backgroundColor } from '@coachhubio/nova-colors';
import { useState, useEffect } from 'react';
import { AudioWave } from './AudioWave';

import Webcam from "react-webcam";
import styled from 'styled-components';

const WebCamContainer = styled.div`

    height: 25vh;
    width: 25vw;
  
    @media only screen and (max-width: 920px) {
      max-height: 20vh; 
      width: 20vw;
    }
`;

const AudioWaveContainer = styled.div`
    float: left;
    left: 22vw;
    bottom: 24vh;
    position: relative;

    @media only screen and (max-width: 920px) {
        left: -1vw;
        float: right;
        bottom: 19.5vh;
    }
`;

const NoCameraContainer = styled.div`
    display: flex;
    background-color: #2D2E39;
    height: 25vh;
    width: 25vw;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 920px) {
        max-height: 20vh; 
        width: 20vw;
    }
`;

const InitialContainer = styled.div`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    color: #1E2D79;
    font-size: 32px;
    background-color: #C6C7EC;
    text-align: center;
    justify-content: center;
    align-items: center;
`;

const Initial = styled.p`
    position: relative;
    text-align: center;
    justify-content:center;
    height: auto;
    bottom: 18px;
`;

export const WebcamComponent = ({ width, height, enabled, name, muted, average }: { width: number, height: number, enabled: boolean, name: string, muted: boolean, average: number }): JSX.Element => {
    const [videoConstraints, setVideoConstraints] = useState({
        width: width,
        height: height,
        aspectRatio: width / height,
        facingMode: "user",
    });

    useEffect(() => {
        setVideoConstraints({
            width: width,
            height: height,
            aspectRatio: width / height,
            facingMode: "user",
        });
    }, [width, height]);

    return (
        <WebCamContainer>
            {
                enabled ?
                    <Webcam
                        audio={false}
                        height="100%"
                        width="100%"
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        mirrored={true}
                    />
                    :
                    <NoCameraContainer>
                        <InitialContainer>
                            <Initial>
                            {name?.substring(0, 1).toUpperCase()}
                            </Initial>
                        </InitialContainer>
                    </NoCameraContainer>
            }
            <AudioWaveContainer>
                <AudioWave thinking={false} muted={muted} average={average}></AudioWave>
            </AudioWaveContainer>
        </WebCamContainer>
    );
};