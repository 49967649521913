import { useEffect } from 'react';
import { User, useUser } from './useUser';
import { useLocalStorage } from './useLocalStorage';

import { auth } from '../utils/firebaseSetup';
import { signInAnonymously, signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { getAnalytics, setUserProperties, setUserId } from "firebase/analytics";


export const useAuth = () => {
  const { user, addUser, removeUser } = useUser();
  const { getItem } = useLocalStorage();

  useEffect(() => {
    //const user = getItem('user');
    if (user) {
      //addUser(JSON.parse(user));
    }
  }, []);

  const login = (user: User) => {
    addUser(user);
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        signInAnonymously(auth)
          .then(() => {
            //console.log("loggeado en analytics como anonimo")
            const analytics = getAnalytics();
            setUserId(analytics, user.coacheeId);
            setUserProperties(analytics, {
              language: user.language,
              session_id: user.sessionId
            });
          });
      })
  };

  const logout = () => {
    signOut(auth).then(() => {
      // console.log('deslogeado')
    });
    removeUser();
  };

  return { user, login, logout };
};