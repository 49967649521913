import React, { useContext } from "react";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { createContext } from 'react';
import { User } from '../hooks/useUser';
import { useAuth } from '../hooks/useAuth';

interface AuthContext {
  user: User | null;
  setUser: (user: User | null) => void;
}

export const AuthContext = createContext<AuthContext>({
  user: null,
  setUser: () => { },
});

export const useAuthState = () => {
  const { user } = useAuth();
  return { ...user, isAuthenticated: user !== null }
}

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuthState()
  //let location = useLocation();
  
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
}