import { useState, useEffect } from 'react';

import { useAudioStreamer, AudioStreamerState } from '../hooks/use-audio-streamer';

import config from '../utils/config';

interface StreamComponentProps {
    setMessage: any
    setAvgSound: any
    question: string,
    mute: boolean,
    token: string,
    sessionId: string,
    isReady: any,
    questionType: number | null,
    webSocketStatus: any
}

export const StreamComponent = ({ setMessage, setAvgSound, question, questionType, mute, token, sessionId, isReady, webSocketStatus }: StreamComponentProps) => {
    const [audioUrl] = useState<string>(`${config.endpoint_transcriber}?token=${token.split(" ")[1]}&session_id=${sessionId}`); // todo: update endpoint
    const { webSocketState, audioStreamState, receivedMessages, averageSound }: AudioStreamerState = useAudioStreamer({
      url: audioUrl,
      mute,
      question,
      questionType
    });
  
    // Update the messages state when a new message is received
    useEffect(() => {
      setMessage(receivedMessages);
    }, [receivedMessages]);

    useEffect(() => {
        setAvgSound(averageSound)
    }, [averageSound])

    useEffect(() => {
      if (webSocketState === 'connected' && audioStreamState) {
        isReady(true)
      }      
    }, [webSocketState, audioStreamState])

    useEffect(() => {
      webSocketStatus(webSocketState)
    }, [webSocketState])
  
    return (
      <div>
        {/* <p>DEBUG MODE</p>
        <p>WebSocket state: {webSocketState}</p>
        <p>Audio stream state: {audioStreamState ? 'active' : 'inactive'}</p>
        <p>Message: {receivedMessages}</p> */}
      </div>
    );
};