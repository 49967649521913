import { useState, useEffect } from 'react';
import "./chat.css";

import { marginStart, paddingTop } from '@coachhubio/nova-spaces';
import { Column, Row, Wrapper, WideButton } from '../components/Common';
import styled from 'styled-components';
import coachhubLogo from '../assets/coachhub-logo.svg';
import config from '../utils/config';
import { ValidateCode } from '../components/ValidateCode';
import { RequestCode } from '../components/RequestCode';
import { RequestCodeRegister } from '../components/RequestCodeRegister';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

const ImageRow = styled(Row)`
  flex-grow: 0;
  ${paddingTop('xl')}
  img {
    height: 40px;
  }

  @media only screen and (max-width: 920px) {
    ${paddingTop('none')}
    img {
        height: 25px;
    }
    justify-content: space-between;
  }
  
`;

const TextRow = styled(Column)`
    font-size: 1.5vh;
    color: #373773;
    @media only screen and (max-width: 920px) {
        width: 95%;
        font-size: 1.5vh
    }
`;

const Content = styled(Wrapper)`
    align-items: center;
    justify-content: center;
    color: #303f9f;
    font-family: 'Montserrat';
    flex-direction: row;
    label, input, button, .MuiFormControl-root {
        font-family: 'Montserrat' !important;
        font-size: 16px;
    }
`

const ColumnSignIn = styled(Column)`
    justify-content: center;
    @media only screen and (min-width: 920px) {
        width: 70%;
        align-items: center;
    }
    @media only screen and (max-width: 920px) {
        width: 80%;
    }
`

const ColumnBackgroundWelcome = styled(Column)`
    flex: 1;
    background-color: #2f2f96 !important;
    background: url('images/welcome.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    height: 100vh;

    @media only screen and (max-width: 920px) {
        display: none;
        flex: 0;
    }
`

const ColumnFooter = styled(Column)`
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        width: 90px;
        margin-left: 20px;
    }
    img:nth-child(1) {
        margin-bottom: 8px;
    }
    img:nth-child(3) {
        margin-left: 10px;
    }

    @media only screen and (max-width: 920px) {
        width: 80%;
        img {
            width: 90px;
            margin-left: 10px;
        }
        img:nth-child(1) {
            margin-left: 70px;
        }
    }
`

const ColumnBetaAbsolute = styled(Column)`
    position: absolute;
    width: 63px;
    float: right;
    top: 25px;
    right: 53vw;

    @media only screen and (max-width: 920px) {
        display: none
    }
`

const BetaImg = styled.img`
    @media only screen and (min-width: 920px) {
        display: none
    }
`

export const SignInScreen = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [register, setRegister] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [busy, setBusy] = useState<null | string>("");
    const [language, setLanguage] = useState(config.default_language);
    const [session, setSession] = useState(null);
    const [username, setUsername] = useState(null);
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorCode, setErrorCode] = useState(false);
    const { login, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            const response = await fetch(config.endpoint_login,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: email.toLowerCase(),
                        username: `${name.substring(0, 1)}${name.substring(1, name.length).toLowerCase()}`,
                        language
                    })
                })

            const res = await response.json();
            if (res.register) {
                setRegister(true)
                setIsLoading(false);
            } else {
                setSession(res.session);
                setUsername(res.username);
                setIsLoading(false);
            }
        } catch (e: any) {
            setIsLoading(false);
        }
    };

    const onSubmitCode = async (data: any) => {
        setIsLoading(true);
        setErrorCode(false);
        try {

            if (name && email && errorCode) {
                onSubmit({
                    email_id: email,
                    username: name
                })
            } else {
                const responseCode = await fetch(config.endpoint_code,
                    {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            code: data.code,
                            username,
                            session
                        })
                    })

                const resCode = await responseCode.json();

                const responseUser = await fetch(config.endpoint_user,
                    {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Authorization': `${resCode.AuthenticationResult.TokenType} ${resCode.AuthenticationResult.IdToken}`
                        }
                    })

                const resUser = await responseUser.json();
                login({
                    id: username!,
                    name: resUser.name,
                    authToken: `${resCode.AuthenticationResult.TokenType} ${resCode.AuthenticationResult.IdToken}`,
                    email,
                    coacheeId: resUser.coachee_id,
                    language: resUser.language,
                    sessionId: resUser.session_id,
                });
                setIsLoading(false);
                navigate('/')
            }
        } catch (e: any) {
            setIsLoading(false);
            setErrorCode(true);
        }
    };

    // useEffect(() => {
    //     const script = document.createElement('script');

    //     script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    //     script.async = true;
    //     script.id = 'usercentrics-cmp';
    //     script.setAttribute('data-settings-id', 'XVAQNSXlcx7wIv');

    //     document.body.appendChild(script);

    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, [])

    useEffect(() => {
        document.title = `${config.page_title} - SignIn`;
        setBusy(searchParams.get('waitlist'))
    }, [])

    return (
        <Content>
            <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                <ColumnBetaAbsolute>
                    <img src="images/beta.png" alt="beta" />
                </ColumnBetaAbsolute>
                <ColumnSignIn>
                    <ImageRow>
                        <img src={coachhubLogo} alt="coachhub logo" />
                        <BetaImg src="images/beta.png" alt="beta" />
                    </ImageRow>
                    {
                        busy ?
                            <div style={{ width: '100%' }}>
                                <p>We are currently experiencing heavy traffic and AIMY is busy. Please try again later</p>
                                <WideButton onClick={()=> {
                                    setBusy("");
                                    searchParams.delete('waitlist');
                                    setSearchParams(searchParams);
                                }}>
                                    Try again
                                </WideButton>
                            </div>
                            :
                            (
                                (session && username) ?
                                    (
                                        <ValidateCode
                                            onSubmit={onSubmitCode}
                                            error={errorCode}
                                            isLoading={isLoading}
                                        ></ValidateCode>
                                    )
                                    :
                                    (
                                        (register) ?
                                            <RequestCodeRegister
                                                onSubmit={onSubmit}
                                                error={errorLogin}
                                                isLoading={isLoading}
                                                setName={setName}
                                                name={name}
                                                setEmail={setEmail}
                                                email={email}
                                                language={language}
                                                setLanguage={setLanguage}
                                            ></RequestCodeRegister>
                                            :
                                            <RequestCode
                                                onSubmit={onSubmit}
                                                error={errorLogin}
                                                isLoading={isLoading}
                                                setEmail={setEmail}
                                                email={email}
                                                language={language}
                                                setLanguage={setLanguage}
                                            ></RequestCode>
                                    )
                            )
                    }
                    <TextRow>
                        <h3 style={{ margin: '0', marginTop: '15px' }}>Research Notice:</h3>
                        <p>
                            Coaching ethics and your privacy are very important for us at CoachHub.
                            AIMY™ takes notes. While you test AIMY™ for free,
                            AIMY™ also creates anonymized transcripts of conversations that may be kept and reviewed to help us train AIMY™,
                            better serve you, and improve our services.
                            We use advanced technologies of third parties such as Amazon AWS and OpenAI.
                            Please do not share any sensitive information.
                            Despite safeguards, AIMY™ may occasionally provide inaccurate or misleading information.
                            We may send you promotional emails, and you can unsubscribe at any time.
                            Our <a href="https://www.coachhub.com/research-terms/" target="_blank">research terms and research privacy notice</a> apply.
                        </p>
                        <p style={{ textAlign: 'center' }}>
                            Safety and Data Protection
                        </p>
                        <ColumnFooter>
                            <img src="images/ssl.png" />
                            <img src="images/iso.png" />
                            <img src="images/privacy.png" />
                        </ColumnFooter>
                    </TextRow>
                </ColumnSignIn>
            </Column>
            <ColumnBackgroundWelcome>
            </ColumnBackgroundWelcome>
        </Content>
    );
};
