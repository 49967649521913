
import React, { useEffect, useRef, useState, useMemo } from 'react'
import ReactAudioPlayer from 'react-audio-player';
import { getScaledValue } from '../utils/scaleValue';
import { ctx } from '../utils/audioContext';


interface AudioPlayerProps {
    src: string,
    playing: boolean,
    setAvg: any,
    onEnded: any
}

export const AudioPlayer = ({ src, playing, setAvg, onEnded }: AudioPlayerProps) => {
    const [loaded, setLoad] = useState(false);



    // useEffect(() => {
    //     if(analyser && sourceNode && playing && audioRef) {
    //         sourceNode.connect(analyser);
    //         analyser.connect(ctx.destination);     
    //         setLoad(true)
    //     }
    // }, [playing, analyser, sourceNode, audioRef])

    // useEffect(() => {
    //     if(analyser && sourceNode && sourceNode.numberOfOutputs && analyser.numberOfOutputs && !playing && loaded) {
    //         //sourceNode.disconnect();
    //         //analyser.disconnect();
    //     }
    // }, [playing, analyser, sourceNode, loaded])

    useEffect(() => {
        if (playing && src) {


            const source = ctx.createBufferSource();
            let buffers;

            Promise.all([
                fetch(src)
            ]).then(
                responses => Promise.all(responses.map(r => r.arrayBuffer())),
            ).then(
                buffers => Promise.all(buffers.map(b => ctx.decodeAudioData(b))),
            ).then(audioBuffers => {
                buffers = audioBuffers;
                source.buffer = buffers[0]
                source.connect(ctx.destination);
                let analyser = ctx.createAnalyser();
                var splitter = ctx.createChannelSplitter(1);
                source.connect(splitter);
                splitter.connect(analyser, 0);
                source.start();

                const start = () => {
                    if (!analyser) return;
                    var array = new Uint8Array(analyser!.frequencyBinCount);
                    analyser!.getByteFrequencyData(array);
                    let values = 0;
                    let length = array.length;
                    for (let i = 0; i < length; i++) {
                        values += (array[i]);
                    }
                    setAvg(getScaledValue(values / length, 0, 100, 0, 100));
                };

                const interval = setInterval(start, 50)

                source.onended = () => {
                    clearInterval(interval)
                    setAvg(0)
                    onEnded()
                };
            });

            
        }
    }, [playing, src])
    return (<></>)
}