
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { paddingY } from '@coachhubio/nova-spaces';
import { Row, ColumnButton, ColumnTextField , WideButton } from '../components/Common';
import { CircularProgress, TextField } from '@coachhubio/ui-components';

import styled from 'styled-components';

interface ValidateCodeProps {
    onSubmit: any,
    error: boolean,
    isLoading: boolean
}

const TitleRow = styled(Row)`
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  @media only screen and (max-width: 920px) {
    font-size: 16px;
    ${paddingY('none')}
  }
  ${paddingY('s')}
`;

export const ValidateCode = ({ onSubmit, error, isLoading }: ValidateCodeProps) => {
    const { register, reset, setValue, handleSubmit } = useForm();

    useEffect(() => {
        register("code", { required: true });
    }, []);

    return (
        <div style={{width: '100%'}}>
            <TitleRow>
                <p>
                    We’ve sent you a code by email to talk with AIMY.<br /> Please check in your inbox.
                </p>
            </TitleRow>
            <TitleRow>
                {
                    error ?
                        <p className="error-alert">
                            the code provided is not valid. Please request a new code.
                        </p>
                        :
                        <></>
                }
            </TitleRow>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true} data-testid="user-form-code">
                    <ColumnTextField>
                        <TextField
                            name="code"
                            onChange={e => setValue("code", e.target.value)}
                            placeholder={'Code'}
                            type="text"
                            autoFocus={true}
                            disabled={isLoading}
                        />
                    </ColumnTextField>
                    <ColumnButton>
                        <WideButton type="submit" disabled={isLoading} data-testid="user-button-code">
                            {isLoading ? <CircularProgress size={30} /> : (
                                error ?
                                    'Request new Access Code'
                                    :
                                    'Talk with AIMY'
                            )}
                        </WideButton>
                    </ColumnButton>
            </form>
        </div>
    )
}