import { useState, useEffect } from 'react';
import "./chat.css";

import { marginStart, paddingTop } from '@coachhubio/nova-spaces';
import { Column, Row, Wrapper, ColumnTextField, ColumnButton, WideButton } from '../components/Common';
import { useForm } from 'react-hook-form';
import { CircularProgress, TextField } from '@coachhubio/ui-components';
import styled from 'styled-components';
import coachhubLogo from '../assets/coachhub-logo.svg';
import config from '../utils/config';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';

const ImageRow = styled(Row)`
  flex-grow: 0;
  ${paddingTop('xl')}
  img {
    height: 40px;
  }

  @media only screen and (max-width: 920px) {
    ${paddingTop('none')}
    img {
        height: 25px;
    }
    justify-content: space-between;
  }
  
`;

const TextRow = styled(Column)`
    font-size: 1.5vh;
    color: #373773;
    @media only screen and (max-width: 920px) {
        width: 95%;
        font-size: 1.5vh
    }
`;

const Content = styled(Wrapper)`
    align-items: center;
    justify-content: center;
    color: #303f9f;
    font-family: 'Montserrat';
    flex-direction: row;
    label, input, button, .MuiFormControl-root {
        font-family: 'Montserrat' !important;
        font-size: 16px;
    }
`

const ColumnSignIn = styled(Column)`
    justify-content: center;
    @media only screen and (min-width: 920px) {
        width: 70%;
        align-items: center;
    }
    @media only screen and (max-width: 920px) {
        width: 80%;
    }
`

const ColumnBackgroundWelcome = styled(Column)`
    flex: 1;
    background-color: #2f2f96 !important;
    background: url('images/welcome.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    height: 100vh;

    @media only screen and (max-width: 920px) {
        display: none;
        flex: 0;
    }
`

const ColumnFooter = styled(Column)`
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        width: 90px;
        margin-left: 20px;
    }
    img:nth-child(1) {
        margin-bottom: 8px;
    }
    img:nth-child(3) {
        margin-left: 10px;
    }

    @media only screen and (max-width: 920px) {
        width: 80%;
        img {
            width: 90px;
            margin-left: 10px;
        }
        img:nth-child(1) {
            margin-left: 70px;
        }
    }
`

const ColumnBetaAbsolute = styled(Column)`
    position: absolute;
    width: 63px;
    float: right;
    top: 25px;
    right: 53vw;

    @media only screen and (max-width: 920px) {
        display: none
    }
`

const BetaImg = styled.img`
    @media only screen and (min-width: 920px) {
        display: none
    }
`

export const WaitListScreen = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [sent, setSent] = useState(false);
    const [token, setToken] = useState<string | null>(null);
    const [sessionId, setSessionId] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { user, logout } = useAuth();
    const { register, reset, setValue, handleSubmit } = useForm();


    const onSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            const response = await fetch(config.endpoint_login,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token!
                    },
                    body: JSON.stringify(data)
                })

            // const res = await response.json();
            setSent(true)
            setTimeout(() => {
                navigate('/login')
            }, 1500);
        } catch (e: any) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        register("name", { required: true });
        register("email", { required: false });
        document.title = `${config.page_title} - Waitlist`;
        logout();
    }, [])

    useEffect(() => {
        if(user) { 
            setToken(user.authToken!)
            setSessionId(user.sessionId)
        }
    }, [user])

    return (
        <Content>
            <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                <ColumnBetaAbsolute>
                    <img src="images/beta.png" alt="beta" />
                </ColumnBetaAbsolute>
                <ColumnSignIn>
                    <ImageRow>
                        <img src={coachhubLogo} alt="coachhub logo" />
                        <BetaImg src="images/beta.png" alt="beta" />
                    </ImageRow>
                    {
                        sent ?
                            <p>Thank you very much, we will send you a notification when the service is available again.</p>
                            :
                            (
                                <div style={{ width: '100%' }}>
                                    <p>
                                        We are currently experiencing heavy traffic and AIMY is busy. <br />
                                        Please sign up and we will inform you once she is free.
                                    </p>
                                    <form onSubmit={handleSubmit(onSubmit)} noValidate={true} data-testid="user-form">
                                        <ColumnTextField>
                                            <TextField
                                                name="name"
                                                onChange={e => setValue("name", e.target.value)}
                                                placeholder={'First Name'}
                                                type="text"
                                                autoFocus={true}
                                                disabled={isLoading}
                                                value={name}
                                                onInput={e => setName((e.target as HTMLInputElement).value)}
                                            />
                                        </ColumnTextField>
                                        <ColumnTextField style={{ marginTop: '-9px' }}>
                                            <TextField
                                                name="email"
                                                onChange={e => setValue("email", e.target.value)}
                                                placeholder={'Business Email'}
                                                type="text"
                                                autoFocus={true}
                                                disabled={isLoading}
                                                value={email}
                                                onInput={e => setEmail((e.target as HTMLInputElement).value)}
                                            />
                                        </ColumnTextField>
                                        <ColumnButton>
                                            <WideButton type="submit" disabled={isLoading} data-testid="user-button">
                                                {isLoading ? <CircularProgress size={30} /> : 'Request Access'}
                                            </WideButton>
                                        </ColumnButton>
                                    </form>
                                </div>)
                    }
                    <TextRow>
                        <h3 style={{ margin: '0', marginTop: '15px' }}>Research Notice:</h3>
                        <p>
                            Coaching ethics and your privacy are very important for us at CoachHub.
                            AIMY™ takes notes. While you test AIMY™ for free,
                            AIMY™ also creates anonymized transcripts of conversations that may be kept and reviewed to help us train AIMY™,
                            better serve you, and improve our services.
                            We use advanced technologies of third parties such as Amazon AWS and OpenAI.
                            Please do not share any sensitive information.
                            Despite safeguards, AIMY™ may occasionally provide inaccurate or misleading information.
                            We may send you promotional emails, and you can unsubscribe at any time.
                            Our <a href="https://www.coachhub.com/research-terms/" target="_blank">research terms and research privacy notice</a> apply.
                        </p>
                        <p style={{ textAlign: 'center' }}>
                            Safety and Data Protection
                        </p>
                        <ColumnFooter>
                            <img src="images/ssl.png" />
                            <img src="images/iso.png" />
                            <img src="images/privacy.png" />
                        </ColumnFooter>
                    </TextRow>
                </ColumnSignIn>
            </Column>
            <ColumnBackgroundWelcome>
            </ColumnBackgroundWelcome>
        </Content>
    );
};
