import {useEffect} from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { useLocation, Location } from 'react-router-dom';
import { firebaseConfig } from "utils/firebaseSetup";



function sendPageView(location: Location) {
  ReactGA.set({page: location.pathname});
  ReactGA.pageview(location.pathname);
}

const GAListener: React.FC = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(firebaseConfig.measurementId);
    sendPageView(location);
  }, [location]);

  return (
    <>{children}</>
  );
}

GAListener.propTypes = {
  children: PropTypes.node,
  trackingId: PropTypes.string,
};

export default GAListener;