import { useState, useEffect } from 'react';
import { getScaledValue } from '../utils/scaleValue';
import { ctx, scriptNode, analyser, mediaStream } from '../utils/audioContext';
import { QuestionTypeEnum } from '../utils/types'
import { getAnalytics, logEvent } from "firebase/analytics";

type WebSocketState = 'disconnected' | 'connecting' | 'connected' | 'error' | 'unauthorized';

export interface UseAudioStreamerOptions {
  url: string;
  mute: boolean;
  question: string;
  questionType: number | null;
}

export interface AudioStreamerState {
  webSocketState: WebSocketState;
  audioStreamState: boolean;
  receivedMessages: null | string;
  averageSound: number;
}

let webSocket: WebSocket;

export const useAudioStreamer = (options: UseAudioStreamerOptions) => {
  const { url, mute, question, questionType } = options;

  const [audioStreamerState, setAudioStreamerState] = useState<AudioStreamerState>({
    webSocketState: 'disconnected',
    audioStreamState: false,
    receivedMessages: null,
    averageSound: 0
  });
  
  useEffect(() => {
    let average: number;
    const initialize = async () => {
      try {
        // Configure the WebSocket
        
        webSocket = new WebSocket(url);
        setAudioStreamerState(prevState => ({ ...prevState, webSocketState: 'connecting' }));
        //console.log('connecting')

        webSocket.addEventListener('open', () => {
          setAudioStreamerState(prevState => ({ ...prevState, webSocketState: 'connected' }));
          const analytics = getAnalytics();
          logEvent(analytics, 'login', {
            method: 'websocket'
          });
          //console.log('connected')
          // Send audio data to the WebSocket
          scriptNode.addEventListener('audioprocess', (event: AudioProcessingEvent) => {
            let array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);

            let values = 0;
            let length = array.length;
            for (let i = 0; i < length; i++) {
              values += (array[i]);
            }

            average = getScaledValue(values / length, 0, 100, 0, 80);
            setAudioStreamerState(prevState => ({ ...prevState, averageSound: average }));

            if (average > 0.5) {
              const inputData = event.inputBuffer.getChannelData(0);
              const buffer = new Int16Array(inputData.length);
              let min = inputData[0] * 15000;
              let max = inputData[0] * 15000;
              for (let i = 0; i < inputData.length; i++) {
                buffer[i] = inputData[i] * 15000;

                if (min > buffer[i]) {
                  min = buffer[i];
                }
                if (max < buffer[i]) {
                  max = buffer[i];
                }
              }
              //if (max > 5000 && Math.abs(min) > 5000) {
              webSocket.send(buffer);
              //}
            }
          });
        });

        webSocket.addEventListener('error', () => {
          setAudioStreamerState(prevState => ({ ...prevState, webSocketState: 'error' }));
          //console.log('error')
        });

        webSocket.addEventListener('close', (event) => {
          setAudioStreamerState(prevState => ({ ...prevState, webSocketState: 'disconnected', audioStreamState: false }));
          const analytics = getAnalytics();
          logEvent(analytics, 'websocket_disconnected');
          const pathname = window.location.pathname;
          // retry connecting after 2 seconds
          if (event.code !== 4001 && event.code !== 1003 && pathname === '/') {
            setTimeout(() => {
              initialize();
            }, 2000);
          } else {
            setAudioStreamerState(prevState => ({ ...prevState, webSocketState: 'unauthorized', audioStreamState: false }));
            webSocket.close()
          }
        });

        // Receive text messages from the WebSocket
        webSocket.addEventListener('message', (event: MessageEvent) => {
          setAudioStreamerState(prevState => ({ ...prevState, receivedMessages: event.data }));
          const analytics = getAnalytics();
          logEvent(analytics, 'message_received');
        });

        setAudioStreamerState(prevState => ({ ...prevState, audioStreamState: true }));
      } catch (error) {
        console.error('Error initializing audio streamer:', error);
      }
    };

    initialize();

    return () => {
      if (webSocket) {
        webSocket.close();
      }
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
      }
      if (ctx) {
        ctx.close();
      }
    };

  }, []);

  useEffect(() => {
    if (question.length > 0 && questionType == QuestionTypeEnum.Text) {
      webSocket.send(question)
    }
  }, [question, questionType])

  useEffect(() => {
    if (mediaStream !== undefined) {
      mediaStream!.getTracks().forEach(track => track.enabled = !mute);
    }
  }, [mediaStream, mute])

  return audioStreamerState;
};