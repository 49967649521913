import { useEffect, useState } from 'react'
import { getScaledValue } from '../utils/scaleValue';

import { makeStyles, createStyles } from '@coachhubio/ui-components';

import {
    MicrophoneSlashIcon,
} from '@coachhubio/nova-icon';


const useStyles = makeStyles((theme) =>
    createStyles({
        imageCropper: {
            width: '36px',
            height: '36px',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '50%',
            backgroundColor: '#0060B2',
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
        },
        dot: {
            backgroundColor: 'white',
            width: '5px',
            height: '5px',
            borderRadius: '50%',
            margin: '2px',
            transition: 'height 100ms',
        },
        speaking: {
            borderRadius: '30%',
        },
        animationThinking: {
            animation: `$thinking 1000ms ${theme.transitions.easing.easeInOut}`,
            animationIterationCount: 'infinite'
        },
        "@keyframes thinking": {
            '0%': {
                marginBottom: '0px',
            },
            '50%': {
                marginBottom: '12px',
            },
            '100%': {
                marginBottom: '0px',
            }
        },
        muted: {
            backgroundColor: '#656780',
            color: 'white',
            opacity: '0.6',
        },
    })
);

interface AudioWaveProps {
    thinking: boolean,
    muted: boolean,
    average: number
}

const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}

export const AudioWave = ({ thinking, muted, average }: AudioWaveProps) => {
    const classes = useStyles();
    const [dotHeight, setDotHeight] = useState([5, 5, 5]);

    useEffect(() => {
        if (average) {
            const scaled = Math.round(getScaledValue(average, 0, 50, 5, 18));
            setDotHeight([scaled, scaled * 1.4, scaled])
        } else {
            setDotHeight([5, 5, 5])
        }
    }, [average])

    return (<div>
        {
            muted ?
                <div className={`${classes.imageCropper} ${muted ? classes.muted : ''}`}>
                    <MicrophoneSlashIcon />
                </div>
                :
                (
                    <div className={`${classes.imageCropper}`}>
                        <div
                            className={`
                ${classes.dot} 
                ${thinking ? classes.animationThinking : ''} 
                ${average ? classes.speaking : ''}
            `}
                            style={{ height: `${dotHeight[0]}px` }}
                        ></div>
                        <div
                            className={`
                ${classes.dot} 
                ${thinking ? classes.animationThinking : ''} 
                ${average ? classes.speaking : ''}
            `}
                            style={{ animationDelay: '100ms', height: `${dotHeight[1]}px` }}
                        ></div>
                        <div
                            className={`
                ${classes.dot} 
                ${thinking ? classes.animationThinking : ''} 
                ${average ? classes.speaking : ''}
            `}
                            style={{ animationDelay: '200ms', height: `${dotHeight[2]}px` }}
                        ></div>
                    </div>
                )
        }
    </div>)
}