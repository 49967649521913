export default {
    page_title: "AIMY™, the world's first conversational AI Coach",
    firebase_api_key: process.env.REACT_APP_FIREBASE_API_KEY || "",
    endpoint_voice: process.env.REACT_APP_ENDPOINT_VOICE || 'http://localhost/voice',
    endpoint_user: process.env.REACT_APP_ENDPOINT_USER || 'http://localhost/user',
    endpoint_login: process.env.REACT_APP_ENDPOINT_LOGIN || 'http://localhost/login',
    endpoint_code: process.env.REACT_APP_ENDPOINT_CODE || 'http://localhost/code',
    endpoint_feedback: process.env.REACT_APP_ENDPOINT_FEEDBACK || 'http://localhost/feedback',
    endpoint_transcriber: process.env.REACT_APP_ENDPOINT_TRANSCRIBE || 'ws://localhost/audio',
    redirect_finish_signin: process.env.REACT_APP_REDIRECT_MAGIC_LINK || 'http://localhost:3000/',
    agent_name: 'AIMY',
    default_language: 'en-US',
    languages: [
        { 
            value: "en-US",
            label: "American English",
        },
        { 
            value: "en-GB",
            label: "British English",
        },
        { 
            value: "es-ES",
            label: "Spanish",
        },
        { 
            value: "fr-FR",
            label: "French",
        },
        { 
            value: "de-DE",
            label: "German",
        },
        { 
            value: "pt-BR",
            label: "Portuguese (Brazil)",
        },
        { 
            value: "pt-PT",
            label: "Portuguese",
        },
        { 
            value: "it-IT",
            label: "Italian",
        },
        { 
            value: "ja-JP",
            label: "Japanese",
        },
    ]
}