import { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { User } from '../hooks/useUser';

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};