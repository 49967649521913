import "./chat.css";

import { useState, useRef, useEffect } from 'react';
import {
  MicrophoneIcon,
  MicrophoneSlashIcon,
  MessageFullIcon,
  XmarkIcon,
  SendIcon,
  CameraIcon,
  CameraSlashIcon,
  PhoneHangupIcon
} from '@coachhubio/nova-icon';
import { margin, paddingX } from '@coachhubio/nova-spaces';
import { Button, TextField, makeStyles, createStyles } from '@coachhubio/ui-components';

import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { WebcamComponent } from '../components/Camera';
import { StreamComponent } from '../components/Stream';
import { OffScreen } from '../components/OffScreen';
import { Column, Row, Wrapper, ButtonIcon } from '../components/Common';

import useWindowSize from "../hooks/use-window-size"
import ReactAudioPlayer from 'react-audio-player';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';

import config from '../utils/config';
import { MessageType, QuestionTypeEnum } from '../utils/types';

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      color: 'black',
      fontSize: '18px',
      fontWeight: 300,
      margin: theme.spacing(3),
      borderRadius: '5px',
      maxHeight: '100vh'
    },
    blockquote: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const ColumnTextField = styled(Column)`
  flex: 1;
  ${paddingX('xs')}
  color: white;
  ${margin('s')}
`

const SmallButton = styled(Button)`
  padding: 8px;
  border: none !important;
  max-width: 40px !important;
  max-height: 40px !important;
  margin: 10px 5px 10px 5px !important;
`

const HangButton = styled(Button)`
  padding: 8px;
  border: none !important;
  max-width: 56px !important;
  max-height: 40px !important;
  margin: 10px 5px 10px 15px !important;
  background-color: #F04729 !important;
  color: white !important;
  
  
  &:hover {
    background-color: #BF280D !important;
    color: white !important;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  }
  
  &:focus {
    background-color: #BF280D !important;
    color: white !important;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3);
  }
`

const WebCamContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2vw;
  height: 25vh;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.5);

  @media only screen and (min-width: 920px) {
    top: 2vh;
  }

  @media only screen and (max-width: 920px) {
    bottom: 10vh;
    max-height: 20vh;
    width: 20vw;
  }
`

const CoachHubLogo = styled.div`
 background: url("images/coachhub_logo_white.png" );
 background-repeat: no-repeat;
 height: 40px;
 width: 180px;
 display: flex;
 flex-direction: column;
 position: absolute;
 top: 2vh;
 left: 2vw;
 color: white;
 font-weight: 700;

 @media only screen and (max-width: 920px) {
  background: url("images/logo.png" );
  background-size: contain;background-repeat: no-repeat;
  height: 20px;
  width: 200px;
 }
`;

const TextDescription = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 5vh;
  left: 2vw;
  width: 60vw;
  color: white;
  font-weight: 700;

  @media only screen and (max-width: 920px) {
    top: 2vh;
    left: 10vw;
    font-weight: 600;
    font-size: 16px;
  }
`;

const ColumnHangoutButtonDesktop = styled(Column)`
  @media only screen and (max-width: 920px) {
    display: none;
  }
`;

const ColumnHangoutButtonMobile = styled(Column)`
  display: none;
  @media only screen and (max-width: 920px) {
    display: block;
  }
`;

const MessagesBox = styled.div`
  background: white;
  color: #1E2D79;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 1px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.3);

  @media only screen and (min-width: 920px) {
    right: 2vw;
    bottom: 12vh;
    width: 25vw;
    height: 58vh;
  }

  @media only screen and (max-width: 920px) {
    z-index:100;
    width: 100vw;
    height: 100vh;
  }
`;

const Messages = styled.section`
  overflow-y: auto !important;
  flex: 1;
`;


export const MainScreen = () => {
  const classes = useStyles();
  const { register, reset, setValue, handleSubmit } = useForm();
  const [apiErrorText, setApiErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessage] = useState([] as MessageType[]);
  const [countMessages, setCountMessages] = useState(0);
  const [getQuestion, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState<QuestionTypeEnum | null>(null)
  const [coacheeId, setCoacheeId] = useState<string | null>(null);
  const [inputQuestion, SetInputQuestion] = useState("");
  const [isProcessing, setProcessing] = useState(false);
  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState<null | string>(null);
  const [audioSource, setAudioSource] = useState("");
  const [playing, setPlaying] = useState(false);
  const [messageBox, showMessages] = useState(false);
  const [muted, setMute] = useState(true);
  const [manualMuted, setManualMute] = useState(false);
  const [webSocketStatus, setWebSocketStatus] = useState("close");
  const [avgSound, setAvgSound] = useState(0);
  const [thinking, setThinking] = useState(false);
  const [name, setName] = useState<string>("");
  const [sessionId, setSessionId] = useState("");
  const [token, setToken] = useState<string | null>(null);
  const [language, setLanguage] = useState<string | null>(null);
  const [camera, setCamera] = useState(true);
  const [streamReady, setStreamReady] = useState(false);
  const { user, logout } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  // End of play
  function playerEnded(e: Event) {
    setAudioSource("");
    setSpeak(false);
    setPlaying(false);
    setProcessing(false);
  }

  // Player is read
  function playerReady(e: Event) {
    if (!playing) {
      setPlaying(true);
    }
  }

  const ref = useRef<HTMLDivElement>(null);
  let elRefs = useRef<HTMLDivElement>(null);
  const dummy = useRef<HTMLDivElement>(null);

  const errorText = apiErrorText ? 'error' : '';

  const { width, height }: { width: number, height: number } = useWindowSize();

  const callAudioText = async (data: any) => {

    if (isProcessing) return;
    setProcessing(true);
    setMute(true)
    const text = data['message'].split('\n').filter((t: string) => t.length).map((t: string) => t.replace(/[`~#%^&*()_|+\-=;:"<>\{\}\[\]\\\/]/gi, ''));
    setAudioSource("data:audio/mp3;base64," + data['audio']);
    setSpeak(true);
    setPlaying(false);
    setIsLoading(false);
    await showTextBot(false, text, [0, 0], 15, countMessages);
  }

  var showTextBot = async function (fromMe: boolean, message: string[], index: number[], interval: number, lastMessage: number) {
    const currentDate = new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
    if (index[0] < message.length) {
      let newMessages = [...messages];
      let text: any = message.map((m: string, i: number) => {
        if (i == index[0]) {
          return <p key={i}>{m.substring(0, index[1]++)}</p>
        } else if (i < index[0]) {
          return <p key={i}>{m}</p>
        }
      })

      if (index[1] > message[index[0]].length) {
        index[0]++;
        index[1] = 0;
      }
      newMessages[lastMessage + 1] = {
        fromMe,
        text,
        timestamp: currentDate
      }
      await setMessage((prev) => [...prev.slice(0, prev.length - 1), newMessages[lastMessage + 1]]);
      setTimeout(function () { showTextBot(fromMe, message, index, interval, lastMessage); }, interval);
    } else {
      await setCountMessages(countMessages + 1);
    }
  }

  const onSubmit = async (data: any) => {
    setApiErrorText('');
    setIsLoading(true);
    try {
      SetInputQuestion("")
      const currentDate = new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
      setQuestion(data.question);
      await setMessage((prev) => [...prev, {
        fromMe: true,
        text: data.question,
        timestamp: currentDate
      }]);
      let count = countMessages;
      await setCountMessages(count++);

    } catch (e: any) {
      setApiErrorText(e.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dummy !== null && dummy !== undefined) {
      dummy?.current?.scrollIntoView({ block: "end" });
    }
    if (messages.length && messages[messages.length - 1].fromMe && getQuestion.length > 1) {
      reset();
      const currentDate = new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
      setMessage((prev) => [...prev, {
        fromMe: false,
        text: "Typing...",
        timestamp: currentDate
      }]);
      setCountMessages(countMessages + 1);
      setThinking(true);
      setMute(true);
    }
  }, [messages, getQuestion]);

  useEffect(() => {
    register("question", { required: true });
    document.title = `${config.page_title} - Meeting`;
  }, [])

  useEffect(() => {
    if (inputQuestion) {
      setQuestion("")
      setQuestionType(QuestionTypeEnum.Text)
    }
  }, [inputQuestion])

  useEffect(() => {
    if (text) {
      const data = JSON.parse(text!);
      if ('audio' in data) {
        callAudioText(data)
        setThinking(true)
      } else if ('transcribed' in data) {
        setQuestionType(QuestionTypeEnum.Voice)
        const question = data.transcribed;
        if (question.length > 1 && !playing) {
          onSubmit({ question });
        }
      } else if ('message' in data) {
        if (data['message'] === 'limit_reached') {
          //navigate('/waitlist', { replace: true })
          logout()
          navigate('/login?waitlist=true', { replace: true,  })
        } else if (data['message'] === 'Endpoint request timed out') {
          setMute(false);
        }
      }
    }
  }, [text])

  useEffect(() => {
    if (avgSound > 6 && !thinking) {
      setThinking(true)
    }
  }, [avgSound, thinking])

  useEffect(() => {
    if (playing) {
      setMute(true)
    }
    if (!playing && !manualMuted && !thinking) {
      setMute(false)
    }
  }, [playing, thinking])

  useEffect(() => {
    if (webSocketStatus === 'unauthorized') {
      logout()
      setCoacheeId(null)
      setName("")
      setToken(null)
      setLanguage(null)
      setSessionId("");
      navigate('/feedback', { replace: true })
    }
  }, [webSocketStatus])

  const sendFeedback = async (token: string, sessionId: string) => {
    await fetch(config.endpoint_feedback,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token!
        },
        body: JSON.stringify({
          feedback: "",
          rating: 0,
          session_id: sessionId
        })
      })
  }

  useEffect(() => {
    if (user) {
      setCoacheeId(user.coacheeId)
      setName(user.name)
      setToken(user.authToken!)
      setLanguage(user.language)
      setSessionId(user.sessionId);

      window.addEventListener("beforeunload", (ev) => {
        // console.log(user.authToken!, user.sessionId)
        sendFeedback(user.authToken!, user.sessionId)
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
      });
    }
  }, [user])

  return (
    <Wrapper ref={ref}>
      <Column>
        <Row>
          <OffScreen
            speak={speak}
            playing={playing}
            playAudio={playerReady}
            thinking={thinking}
            setThinking={setThinking}
            loaded={coacheeId != null}
            src={audioSource}
            onEnded={playerEnded}
          />
          {/* <ReactAudioPlayer
            src={audioSource}
            ref={audioPlayer}
            onEnded={playerEnded}
          /> */}
          <CoachHubLogo>
          </CoachHubLogo>
          <TextDescription>
            Coaching {config.agent_name} & {name}
          </TextDescription>
          <div className="button-wrap-left">
            <Row>
              <ColumnHangoutButtonMobile>
                <HangButton
                  onClick={() => navigate('/feedback', { replace: true })}
                >
                  <PhoneHangupIcon style={{
                    marginBottom: '5px'
                  }} />
                </HangButton>
              </ColumnHangoutButtonMobile>
            </Row>
          </div>
          <div className="button-wrap-middle">
            <Row>
              <Column>
                <SmallButton
                  className={muted ? 'off-button' : 'stand-button'}
                  // style={{
                  //   outline: `${avgSound}px solid rgba(163, 206, 251, ${avgSound / 20})`
                  // }}
                  onClick={() => {
                    setMute(playing ? muted : !muted);
                    setManualMute(!manualMuted);
                  }}
                >
                  {
                    muted ?
                      <MicrophoneSlashIcon style={{
                        marginBottom: '5px'
                      }} />
                      :
                      <MicrophoneIcon style={{
                        marginBottom: '5px'
                      }} />
                  }
                </SmallButton>
              </Column>
              <Column>
                <SmallButton
                  className={!camera ? 'off-button' : 'stand-button'}
                  onClick={() => setCamera(!camera)}
                >
                  {
                    !camera ?
                      <CameraSlashIcon style={{
                        marginBottom: '5px'
                      }} />
                      :
                      <CameraIcon style={{
                        marginBottom: '5px'
                      }} />

                  }
                </SmallButton>
              </Column>
              <ColumnHangoutButtonDesktop>
                <HangButton
                  className="hang-button"
                  onClick={() => navigate('/feedback')}
                >
                  <PhoneHangupIcon style={{
                    marginBottom: '5px'
                  }} />
                </HangButton>
              </ColumnHangoutButtonDesktop>
            </Row>
          </div>
          <div className="button-wrap-right">
            <Row>
              <Column>
                <SmallButton
                  className={messageBox ? 'clicked-button' : 'stand-button'}
                  onClick={() => showMessages(!messageBox)}
                >
                  <MessageFullIcon style={{
                    marginBottom: '5px'
                  }} />
                </SmallButton>
              </Column>
            </Row>
          </div>
          {
            (messageBox) ?
              <MessagesBox>
                <Row style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  maxHeight: '56px',
                  padding: '0 20px 0 20px',
                  borderBottom: '1px solid #C6C7D2'
                }}>
                  <Column>
                    <h3>In-call messages</h3>
                  </Column>
                  <XmarkIcon
                    onClick={() => showMessages(!messageBox)}
                    size="s"
                  />
                </Row >
                <Messages>
                  <section>
                    {messages.map((msg, i) =>
                      <div
                        key={i}
                        className={`msg ${!msg.fromMe ? 'msg--me' : 'msg--me'}`}
                        ref={elRefs}
                      >
                        <blockquote className={classes.blockquote}>
                          <b>{msg.fromMe ? name : config.agent_name}</b> {msg.timestamp}<br />
                          {msg.text}
                        </blockquote>
                      </div>
                    )}
                    <div ref={dummy} />
                  </section>
                </Messages>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true} data-testid="chat-form">
                  <Row style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxHeight: '56px',
                    padding: '0 20px 10px 0',
                    borderBottom: '1px solid #C6C7D2'
                  }}>
                    <ColumnTextField>
                      <TextField
                        name="question"
                        placeholder={'Send a message to AIMY'}
                        type="text"
                        fullWidth={true}
                        autoComplete="question"
                        autoFocus={true}
                        error={!!errorText}
                        disabled={isLoading || playing}
                        value={inputQuestion}
                        onChange={e => setValue("question", e.target.value)}
                        onInput={e => SetInputQuestion((e.target as HTMLInputElement).value)}
                      >
                      </TextField>
                    </ColumnTextField>
                    <ButtonIcon disabled={isLoading || playing} type="submit">
                      <SendIcon />
                    </ButtonIcon>
                  </Row>
                </form>
              </MessagesBox>
              : <></>
          }
          <WebCamContainer>
            <WebcamComponent
              width={width / 4}
              height={height / 4}
              enabled={camera}
              name={name}
              muted={muted}
              average={avgSound}
            />
          </WebCamContainer>
          {
            token && sessionId ?
              <div className='audio-component'>
                <StreamComponent
                  setMessage={setText}
                  setAvgSound={setAvgSound}
                  mute={muted}
                  token={token}
                  sessionId={sessionId}
                  question={getQuestion}
                  questionType={questionType}
                  isReady={setStreamReady}
                  webSocketStatus={setWebSocketStatus}
                />
              </div>
              : <></>
          }
        </Row>
      </Column>
    </Wrapper>
  );
};
